@charset "utf-8";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 1em;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body *{
	box-sizing:border-box;
}

body{
    font-family:'-apple-system', 'BlinkMacSystemFont', 'Apple SD Gothic Neo',
				'Inter', 'Noto Sans KR',
				'Segoe UI', Sans-Serif,
				'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    color: #333;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button,
input, select, textarea, button{
	font: inherit;	
}

ol, ul {
	list-style:none;
}

blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

ins {
	text-decoration: none;
}
del {
	text-decoration: line-through;
}

table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

a, button,
input[type="button"], input[type="submit"] {
	cursor: pointer;
	vertical-align: middle;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

a:visited {
	color:#555;
}
input[type="search"],
input[type="text"],
input[type="number"],
input[type="password"]{
	width:100%;
}

:focus {
    box-shadow: 0 1px 3px 3px var(--color-focus);
    outline: var(--color-focus-border) solid 1px;
}

::placeholder,
input::placeholder,
textarea::placeholder{
	font-size:.9em;
	color:#ccc;
}
