@charset "utf-8";

/* width */
.max-w30{ max-width: 30px;}
.max-w40{ max-width: 40px;}
.max-w50{ max-width: 50px;}
.max-w60{ max-width: 60px;}
.max-w70{ max-width: 70px;}
.max-w80{ max-width: 80px;}
.max-w90{ max-width: 90px;}
.max-w100{ max-width: 100px;}
.max-w110{ max-width: 110px;}
.max-w120{ max-width: 120px;}
.max-w130{ max-width: 130px;}
.max-w140{ max-width: 140px;}
.max-w150{ max-width: 150px;}
.max-w160{ max-width: 160px;}
.max-w170{ max-width: 170px;}
.max-w180{ max-width: 180px;}
.max-w190{ max-width: 190px;}
.max-w200{ max-width: 200px;}
.max-w210{ max-width: 210px;}
.max-w220{ max-width: 220px;}
.max-w230{ max-width: 230px;}
.max-w240{ max-width: 240px;}

.wh--full{ width: 100%; height: 100%; }

.of--hidden{ overflow:hidden; }
.of-x--auto{ overflow-x:auto; }
.of-y--auto{ overflow-y:auto; }

/* display */
.disp--block{ display: block; }
.disp--inline{ display: inline; }
.disp--inline-block{ display: inline-block; }
.disp--flex{ display: flex;}
.disp--inline-flex{ display: inline-flex; }

.f-dir--row{ flex-direction: row; }
.f-dir--col{ flex-direction: column; }
.f-wrap{ flex-wrap: wrap; }
.f-nowrap{ flex-wrap: nowrap; }

.f-jc--center{ justify-content: center; }
.f-jc--start{ justify-content: flex-start; }
.f-jc--end{ justify-content: flex-end; }
.f-jc--between{ justify-content: space-between; }
.f-jc--around{ justify-content: space-around; }
.f-jc--evenly{ justify-content: space-evenly; }
.f-ai--center{ align-items: center; }
.f-ai--start{ align-items: flex-start; }
.f-ai--end{ align-items: flex-end; }
.f-ai--stretch{ align-items: stretch; }
.f-ai--baseline{ align-items: baseline; }

.f-js--start{justify-self: flex-start;}
.f-js--end{justify-self: flex-end;}
.f-as--start{align-self: flex-start;}
.f-as--end{align-self: flex-end;}

.flex0{ flex:0; }
.flex0-auto{ flex:0 0 auto; }
.flex0-1{ flex:0 0 100%; }
.flex0-2{ flex:0 0 50%; }
.flex0-3{ flex:0 0 33.33%; }
.flex0-4{ flex:0 0 25%; }
.flex0-5{ flex:0 0 20%; }
.flex0-6{ flex:0 0 16.66%; }
.flex0-7{ flex:0 0 14.28%; }
.flex0-8{ flex:0 0 12.5%; }
.flex0-9{ flex:0 0 11.11%; }
.flex0-10{ flex:0 0 10%; }
.flex0-11{ flex:0 0 9.09%; }
.flex0-12{ flex:0 0 8.33%; }

.flex1{ flex:1; }
.flex1-auto{ flex:1 1 auto; }
.flex1-1{ flex:1 1 100%; }
.flex1-2{ flex:1 1 50%; }
.flex1-3{ flex:1 1 33.33%; }
.flex1-4{ flex:1 1 25%; }
.flex1-5{ flex:1 1 20%; }
.flex1-6{ flex:1 1 16.66%; }
.flex1-7{ flex:1 1 14.28%; }
.flex1-8{ flex:1 1 12.5%; }
.flex1-9{ flex:1 1 11.11%; }
.flex1-10{ flex:1 1 10%; }
.flex1-11{ flex:1 1 9.09%; }
.flex1-12{ flex:1 1 8.33%; }

/* margin */
.mt-auto{ margin-top: auto; }
.mr-auto{ margin-right: auto; }
.mb-auto{ margin-bottom: auto; }
.ml-auto{ margin-left: auto; }
.mg01{margin:.1em;}
.mg02{margin:.2em;}
.mg03{margin:.3em;}
.mg04{margin:.4em;}
.mg05{margin:.5em;}
.mg06{margin:.6em;}
.mg07{margin:.7em;}
.mg08{margin:.8em;}
.mg09{margin:.9em;}
.mg10{margin:1em;}
.mr01{margin-right:.1em;}
.mr02{margin-right:.2em;}
.mr03{margin-right:.3em;}
.mr04{margin-right:.4em;}
.mr05{margin-right:.5em;}
.mr06{margin-right:.6em;}
.mr07{margin-right:.7em;}
.mr08{margin-right:.8em;}
.mr09{margin-right:.9em;}
.mr10{margin-right:1em;}
.ml01{margin-left:.1em;}
.ml02{margin-left:.2em;}
.ml03{margin-left:.3em;}
.ml04{margin-left:.4em;}
.ml05{margin-left:.5em;}
.ml06{margin-left:.6em;}
.ml07{margin-left:.7em;}
.ml08{margin-left:.8em;}
.ml09{margin-left:.9em;}
.ml10{margin-left:1em;}
.mt01{margin-top:.1em;}
.mt02{margin-top:.2em;}
.mt03{margin-top:.3em;}
.mt04{margin-top:.4em;}
.mt05{margin-top:.5em;}
.mt06{margin-top:.6em;}
.mt07{margin-top:.7em;}
.mt08{margin-top:.8em;}
.mt09{margin-top:.9em;}
.mt10{margin-top:1em;}
.mb01{margin-bottom:.1em;}
.mb02{margin-bottom:.2em;}
.mb03{margin-bottom:.3em;}
.mb04{margin-bottom:.4em;}
.mb05{margin-bottom:.5em;}
.mb06{margin-bottom:.6em;}
.mb07{margin-bottom:.7em;}
.mb08{margin-bottom:.8em;}
.mb09{margin-bottom:.9em;}
.mb10{margin-bottom:1em;}

/* paddin */
.pd01{padding:.1em;}
.pd02{padding:.2em;}
.pd03{padding:.3em;}
.pd04{padding:.4em;}
.pd05{padding:.5em;}
.pd06{padding:.6em;}
.pd07{padding:.7em;}
.pd08{padding:.8em;}
.pd09{padding:.9em;}
.pd10{padding:1em;}
.pr01{padding-right:.1em;}
.pr02{padding-right:.2em;}
.pr03{padding-right:.3em;}
.pr04{padding-right:.4em;}
.pr05{padding-right:.5em;}
.pr06{padding-right:.6em;}
.pr07{padding-right:.7em;}
.pr08{padding-right:.8em;}
.pr09{padding-right:.9em;}
.pr10{padding-right:1em;}
.pl01{padding-left:.1em;}
.pl02{padding-left:.2em;}
.pl03{padding-left:.3em;}
.pl04{padding-left:.4em;}
.pl05{padding-left:.5em;}
.pl06{padding-left:.6em;}
.pl07{padding-left:.7em;}
.pl08{padding-left:.8em;}
.pl09{padding-left:.9em;}
.pl10{padding-left:1em;}
.pt01{padding-top:.1em;}
.pt02{padding-top:.2em;}
.pt03{padding-top:.3em;}
.pt04{padding-top:.4em;}
.pt05{padding-top:.5em;}
.pt06{padding-top:.6em;}
.pt07{padding-top:.7em;}
.pt08{padding-top:.8em;}
.pt09{padding-top:.9em;}
.pt10{padding-top:1em;}
.pb01{padding-bottom:.1em;}
.pb02{padding-bottom:.2em;}
.pb03{padding-bottom:.3em;}
.pb04{padding-bottom:.4em;}
.pb05{padding-bottom:.5em;}
.pb06{padding-bottom:.6em;}
.pb07{padding-bottom:.7em;}
.pb08{padding-bottom:.8em;}
.pb09{padding-bottom:.9em;}
.pb10{padding-bottom:1em;}

/* list style */
.list{ display: flex; align-items: stretch; padding:.2em; }
.list--inline{ flex-wrap: nowrap; overflow-x: auto;}
.list--block{ flex-wrap: wrap; }

.list__item{padding:.2em;}
.list--block .list__item{overflow: hidden;}
.list--block.item--c1 .list__item{ flex:0 1 100%; }
.list--block.item--c2 .list__item{ flex:0 1 50%; }
.list--block.item--c3 .list__item{ flex:0 1 33.33%; }
.list--inline .list__item{ flex: 0 0 auto; }

/* layerpopup */
.layerpopup{ visibility: hidden; width:0; height:0; display:flex; justify-content: center; align-items: flex-end; opacity: 0; z-index: -1; transition: opacity .3s ease-in; }
.layerpopup__contents{
    display: flex; flex-direction: column;
    background: #fff; overflow: hidden; box-shadow: 0 0 6px 3px rgb(0 0 0 / 30%);
    opacity: 0; transform: translateY(30px); transition: all .5s ease-in;
}
.layerpopup__header{ flex: 0 0 auto; display: flex; justify-content: space-between; align-items: center; padding: .4em; overflow: hidden; }
.layerpopup__header >h2{ padding: 0.4em; font-size:1.1em; color: var(--color-default); }
.layerpopup__body{ flex:1; padding:.8em; overflow-y: auto; }
.layerpopup__footer{ flex:0 0 auto; display:flex; }
.layerpopup__footer .btn{ flex: 1; padding: 1em 0.8em; border: 0; border-radius: 0; }

/* notifications */
.notifications{ 
    display: flex; align-items: flex-start; justify-content: flex-start;
    width: 80%; padding: 0.8em; background: #444; border-radius: 10px; font-size: 14px;
    position: fixed; top:30px; left: 50%; margin-left: -40%; transform: translateY(-200%) ; z-index: 99;
    overflow: hidden; box-shadow: 3px 4px 2px 0px rgb(88 88 88 / 30%);
    animation: 3s slideDown ease-in-out 0s 1 forwards;
}
.notifications:hover {
    animation-play-state: paused;
}
.notifications__icon{ flex: 0 0 40px; height: 40px; padding: 0.4em; }
.notifications__msg{ flex: 1; padding:.2em .4em; font-size: 1em; line-height: 1.4; word-break: keep-all; word-wrap: break-word; color: #fff; align-self: center;}
.notifications.warning{ border: 2px solid var(--color-warning); }
.check .notifications__icon{ fill:var(--color-guide); border-radius: 50%; border: 3px solid var(--color-guide); }
.check .notifications__icon .svg-img{ margin:0 0 1px -1px; }
.warning .notifications__icon{ fill:var(--color-warning); }

/* dialog */
.dialog{
    display: flex; justify-content: center; align-items: flex-start;
    width:100%; height:100%; overflow: hidden; padding-top: 30px; background: rgba(0,0,0,20%); position:fixed; top:0; left:0; z-index: 999; opacity: 0;
    animation: .5s opacity ease-in-out 0s 1 forwards;
}
.dialog__contents{
    display: flex; flex-direction: column; max-width: 90%; max-height: 90%;
    background: #fff; overflow: hidden; box-shadow: 0 0 6px 3px rgb(0 0 0 / 30%); opacity: 0; 
    animation: .5s dialogSlide ease-in-out 0s 1 forwards;
}
.dialog__icon{ flex: 0 0 auto; width: 40px; height:40px; margin:1.8em auto .4em auto; padding: 0.4em; fill:var(--color-primary); border-radius: 50%; border: 3px solid var(--color-primary); }
.dialog__icon .svg-img{ margin:0 0 1px 0; }
.dialog__msg{ flex: 1; padding:.8em 1.8em; font-size: 1em; line-height: 1.4; align-self: center; overflow-y: auto; text-align: center; }
.dialog__msg >p{ padding:.2em; word-break: keep-all; word-wrap: break-word; }
.dialog__btn{ flex:0 0 40px; display: flex; justify-content: center; margin: .4em 0 2em 0; padding:0 1em; }
.dialog__btn .btn{ flex:1; max-width: 120px; margin-right: .4em; border-radius: 0; padding:0 2em; }
.dialog__btn .btn:last-child{ margin-right: 0; }

/* format style */
.format-card{}
.format-input{padding: .3em;}
.format-input--row{display: flex;flex-direction: row;align-items: baseline;justify-content: flex-start;flex-wrap: wrap;}
.format-input__label{flex:0 0 auto;padding:.2em;font-size: 1em; line-height: 1; color: var(--color-default);}
.format-input__conts{flex:1;padding:.2em;}

/* card style */
.card--linebox{border:1px solid #ddd;}
.card--shadow{box-shadow: 0 1px 3px 3px rgb(90 90 90 / 10%);}

/* table */
caption{ width:100%; text-align: left; color: var(--color-default);}
table th,
table td{ padding:.4em .6em; border:var(--table-border); }
table th{ background-color:#fafafa; font-weight: bold; }
.table--row th{ text-align: left; }

/* popup open 맨 마지막줄로.. */
.popup--open{ visibility: inherit; width:100%; height:100%; overflow: hidden; background: rgba(0,0,0,50%); position:fixed; top:0; left:0; z-index: 98; opacity: 1;}
.popup--open .layerpopup__contents,
.popup--open .dialog__contents{ min-width:200px; max-width:90%; max-height: 90%; transform: translateY(0); opacity: 1; }


/* contents header */
html,body{ width:100%; height:100%; }
.header{ width: 100%; padding:.8em; }
.header >div{ width:100%; }
.header__logo{ width: 7em; overflow: hidden; max-width: 200px; }
.header__logo >a{ display: flex; width:100%; }
.header__logo >a img{ width:100%; }
.sns__wrap{ display: flex; align-items: center; max-width: 16em; }
.sns__li{ width:3em; flex:1; }
.sns__li img{ width:100%; }
/* contents header E */

.header--fixed{ position: fixed; top: 0; left: 0; padding:.2em .8em; z-index: 5; }
.header--fixed >div{ padding:.4em 0; }

.full__section{ width:100%; height:100%; overflow: hidden; }

/* Mobile & Tablet */
@media all and (min-width:480px) {

}

/* Tablet & Desktop */
@media all and (min-width:768px) {
    .list--inline::-webkit-scrollbar { width: 6px; height: 6px; }
    .list--inline::-webkit-scrollbar-track { background-color: #f1f1f1; }
    .list--inline::-webkit-scrollbar-thumb { border-radius:30px; background-color: #ddd; }
    .list--inline::-webkit-scrollbar-button{ display:block; width:6px; height:6px; background-color: #ddd; }
    .notifications{ width: 70%; margin-left: -35%; }
}

/* Desktop */
@media all and (min-width:1025px) {
    .notifications{ width: 500px; margin-left: -250px; font-size: 16px; }
}