@charset "utf-8";

@font-face {
    font-family: 'GmarketSans';
    font-weight: 400;
    src:local('GmarketSansTTFLight'),
        url('../../fonts/GmarketSansTTFLight.woff2') format("woff2"),
        url('../../fonts/GmarketSansTTFLight.woff') format("woff"),
        url('../../fonts/GmarketSansTTFLight.ttf') format("truetype");
}

@font-face {
    font-family: 'GmarketSans';
    font-weight: 600;
    src:local('GmarketSansTTFMedium'),
        url('../../fonts/GmarketSansTTFMedium.woff2') format("woff2"),
        url('../../fonts/GmarketSansTTFMedium.woff') format("woff"),
        url('../../fonts/GmarketSansTTFMedium.ttf') format("truetype");
}

@font-face {
    font-family: 'GmarketSans';
    font-weight: 800;
    src:local('GmarketSansTTFBold'),
        url('../../fonts/GmarketSansTTFBold.woff2') format("woff2"),
        url('../../fonts/GmarketSansTTFBold.woff') format("woff"),
        url('../../fonts/GmarketSansTTFBold.ttf') format("truetype");
}

:root{
    --color-primary : #88BF4D ;
    --color-deepprimary : #719A2F ;
    --color-background : #EFF1F4;

    --color-title: #0E283E;
    --color-wtitle: #FFFFFF;
    --color-text: #333;

    --color-status-cancel : #F36868;
    --color-status-receive : #868DBF;
    --color-status-confirm : #88BB6E;
    --color-status-wait : #FFC466;

    --color-focus : rgb(130 202 83 / 30%);
    --color-focus-border : #719A2F ;
}

.font--main{ font-family: 'GmarketSans', sans-serif; }
.text--color{color:var(--color-primary);}
.text--deepcolor{color:var(--color-deepprimary);}

/* tooltip */
.tooltip{ position:relative; }
.tooltip__btn--open{ width: 16px; height: 16px; border-color:var(--color-guide); border-radius: 50%; padding: 0; display: inline-flex; justify-content: center; align-items: center; }
.tooltip__btn--open >.svg-img{ padding: 2px; fill: var(--color-guide); }
.tooltip__textbox{
    display: flex; justify-content: space-between;
    position: absolute;
    background: #2e2e2e;padding: 5px;border-radius: 5px;box-shadow: 0 0 5px 2px rgb(0 0 0 / 40%);
    color: var(--color-guide);font-size: 13px;line-height: 1.3;/*word-break: keep-all;word-wrap: break-word;*/
    z-index: -1;opacity: 0;transform: scale(0);transition: opacity .6s ease-in-out;
}
.tooltip .btn--close{ flex: 0 0 15px; width: 15px; height: 15px; padding: 2px; margin: 0 2px; }

.position{ transform: scale(1); opacity: 1; z-index: 2; }
.position--topLeft{ top:0; left:0; }
.position--topRight{ top:0; right:0; }
.position--bottomLeft{ bottom:0; left:0; }
.position--bottomRight{ bottom:0; right:0; }

/* toggle */
.c-toggle{background: #fff;}
.toggle__header{}
.toggle__btn{padding: 1em 0.8em;border:0;border-radius: 0;}
.toggle__btn .btn__text{text-align: left;font-size: 1.1em;}
.toggle__btn .btn__icon{transition: all 0.6s ease-in;transform: rotate(0);}
.toggle__body{padding:1em 0.6em;border-top:1px solid #ddd;}
.js-close .toggle__btn .btn__icon{transform: rotate(180deg);}

/* section contents */
.section__container{ max-width:1000px; margin:0 auto; }
footer{padding:2em .4em 8em .4em;}
.footer__logo { max-width: 270px; padding: 1em; }
.footer__logo > img{width: 100%;}
.footer__info{ padding:.2em; line-height: 1.4; }
.footer__info .name{ font-weight: 600; padding: .4em; font-size: 1.1em; }
.footer__info span{ border-right: 1px solid #bbb; display: inline-block; padding: 0 0.6em; line-height: .8; }
.footer__info span:last-child{ border-right: none; }
footer .sns__wrap{ max-width: 14em; padding: 1em 0; margin-left:-.4em; }
footer .sns__li{ padding:.8em 0; margin-right:.4em; }
.copyright{ padding:5em .4em 1em .4em; }
footer .cmt{ font-size: .8em; }

footer .btn__wrap { display: flex; padding: 2em 0; margin-bottom: 1em; }
footer .btn__wrap .btn { padding: 0.6em 1.2em; margin-right: 0.6em; }
/* ondot */
.sc01__conts{ display: flex; justify-content: center; align-content: center; align-items: center; flex-wrap: wrap; flex-direction: column; padding-bottom:2em; }
.top--subtitle ul{ max-width: 150px; }
.top--subtitle li >img{ width: 100%; }
.top--subtitle .dot{ max-width: 14.5px; }
.top--subtitle .dot> img{ width:100%; }
.top--title{ padding:1.8em 1em 1em; max-width: 280px; }
.top--title >img{ width: 100%; }
.app-down{ padding:1.2em 1em; }
.app-down >li{ padding:.4em; }
.app-down a{ display:inline-flex; justify-content: center; align-items: center; width:120px; height:42px; padding:.5em 1.4em; }
.app-down a >img{ width:100%; }

.info{ width:100%; height:100%; overflow: hidden; display: flex; flex-wrap: wrap; flex-direction: column; padding:4em 3em 0 1em; }
.info__text{ flex:0 0 auto; text-align: left; padding:2em 0 2em 0; display: flex; flex-wrap: wrap; }
.info__text .title{ font-size: 2.6em; font-weight: 800; color: var(--color-deepprimary); flex:0 0 auto; margin-right: .4em; }
.info__text .subtitle{ flex:1; font-size: 1.1em; font-weight: 600; color: #000; padding:.2em 0 .4em 0; word-break: keep-all; word-wrap: break-word; }
.info__text .subtitle >span{ display:inline-block;  }
.info__text .mo-text{ flex:0 0 100%; padding-top: .45em; font-size:.8em; font-weight: 400;line-height: 1.3; color: #333; word-break: keep-all; word-wrap: break-word; }
.info__text .text{ display: none; font-size:1em; font-weight: 400;line-height: 1.3; color: #333; word-break: keep-all; word-wrap: break-word; }
.info__text .text2{ display: none;  font-size:1em; font-weight: 400;line-height: 1.3; color: #333; word-break: keep-all; word-wrap: break-word; padding-top:1em; }
.info__text strong{ font-weight:600; color:#000; }
.info__img{ flex:1; padding-top:1em; }
.info__img .allphone{ max-width: 80%; height:140%; margin: 0 0 0 auto;}
.info__img img{width: 100%; height:100%; object-fit: fill;}
.info__img .phone{ width: 100%; max-width:320px; max-height: 662px; position: relative; overflow: hidden; background-color: #fff; }
.info__img .phone::before{content: ""; width: 100%; height: 10px; position: absolute; bottom:0; left:0; background-color: #fff;}
.info__img .phone__conts{ padding: 77px 10px 0 10px; }
.info__img .phone__img{ position: absolute; top:0; left:0; }

.full__sc01{ background:no-repeat url(../../img/ondot_bg_sc01.png) 50% 100% / cover; }
.full__sc02{ background:no-repeat url(../../img/ondot_bg_sc02.png) 0% 100% / cover; }
.full__sc03{ background:no-repeat url(../../img/ondot_bg_sc03.png) 0% 100% / cover; }
.full__sc04{ background:no-repeat url(../../img/ondot_bg_sc04.png) 0% 100% / cover; }
.full__sc05{ background:no-repeat url(../../img/bg_footer.png) 0% 100% / cover; }

.full__sc02 .allphone{ background:no-repeat url(../../img/all_phone_main.png) 100% 0% / contain; }
.full__sc03 .allphone{ background:no-repeat url(../../img/all_phone_hobby.png) 100% 0% / contain; }
.full__sc04 .allphone{ background:no-repeat url(../../img/all_phone_learning.png) 100% 0% / contain; }

.sns__li img.main_icon{ display: none; }
.fp-viewing-main .sns__li img{ display: none; }
.fp-viewing-main .sns__li img.main_icon{ display: block; }
.fp-viewing-footer .header{ display: none; }

.ani_opacity{ opacity: 0;}
.ani_slideUp, .ani_tt,
.ani_slideUp02{opacity:0; transform:translateY(100%);}
.ani_slideDown2{opacity:0; transform:translateY(-100%);}
.active .ani_slideUp{ animation: 1.2s slideUp ease 0s 1 forwards; }
.active .ani_slideUp02{ animation: 1.8s slideUp ease 0s 1 forwards; }
.active .ani_slideDown2{ animation: 1.2s slideDown2 ease 0s 1 forwards; }
.active .ani_opacity{animation: 1.8s opacity ease 0s 1 forwards;}
/*.active .ani_tt { animation: 1.2s tt ease-out 0s 1 forwards; }*/
.active .ani_tt{
	animation-name: bounce;
	-webkit-animation-name: bounce;

	animation-duration: 1s;
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;
	-webkit-animation-timing-function: ease;

    animation-fill-mode: forwards;

}
.fp-section, .fp-tableCell{ overflow: hidden; }
#fp-nav{ top:22.4%;  right: 0.4em; }

/*
.active .ani_tt {
    -webkit-animation-duration: calc(1s * 0.75);
    animation-duration: calc(1s * 0.75);
    -webkit-animation-duration: calc(2s * 0.75);
    animation-duration: calc(2s * 0.75);
    -webkit-animation-name: tt;
    animation-name: tt;
    animation-fill-mode: forwards;
  }

.active  .ani_tt {
    -webkit-animation-name: shakeY;
    animation-name: shakeY;
    animation-fill-mode: forwards;
  }
*/
/* login */
.login__wrap{ padding-top: 4em; }
.login__logo{ width:8em; }
.login__logo >img{ width:100%; }
.login{ width: 100%; padding: 3em 1em;max-width: 420px;}
.login input{ padding: 0.8em; margin-bottom: .4em; }
.login .btn--full{ padding: 0.8em; }
.login__sns{ width:100%; text-align: center; }
.login__sns ul{ display: flex; padding: 0.8em 0;max-width: 380px;width: 100%;margin: 0 auto; }
.login__sns li{flex:1; padding: .8em; }

/* charge */
.charge__wrap .btn,
.layerpopup--ondot .radio--btn input[type="radio"]+label{ border-radius: 8px; padding: 1em; }
.charge__wrap { padding-bottom:2em; max-width: 640px; margin:0 auto; }
.charge__box{ padding:1em 1em .2em 1em; }
.charge__list{ padding:.4em 0 1em 0; }
.charge__list li{ padding:.3em 0;}
.charge__list .btn,
.layerpopup--ondot .radio--btn input[type="radio"]+label{ display: flex; justify-content: space-between; }
.pay--flower{
    display: inline-block; background: no-repeat url(../../img/ic_flower.svg) 0% 50% / 24px; padding:.4em 0; padding-left:30px;
    color: #FF5058; font-size: 1em;
}
.pay--won{ display: inline-block; padding:.4em 0; font-size:1.1em; font-weight: bold; color:#222; }

.ticket__wrap .title{ color:#222; background: no-repeat url(../../img/ic_ticket.svg) 0% 50% / 24px; padding:.4em 0; padding-left:30px; font-weight: bold; font-size: 1em; line-height: 1; }
.ticket__wrap .btn{ width:100%; margin:.5em 0; display: flex; justify-content: space-between; border:0; }
.ticket__wrap .btn >span{ display: inline-block; padding:.4em 0; color:#222; }
.ticket__wrap .btn .white{ color: #fff; }
.btn--meeting{ background-color:#FFA2AC; }
.btn--learning{ background-color:#FFD800; }

.support{ width:100%;  background: #FAFAFA; padding:.8em .2em; display: flex; align-items:baseline; justify-content: center; }
.support >img{ width: 72px; margin-right:.6em; }
.support >span{ display: inline-block; word-break: keep-all;word-wrap: break-word; font-size: .8em; color: #888888;  }

.layerpopup--ondot{align-items: flex-end;}
.layerpopup--ondot.popup--open .layerpopup__contents{
    width: 100%; max-width: 640px; padding: 0 0 1em 0; border-radius: 15px 15px 0 0;
}

.layerpopup--ondot .radio--btn input[type="radio"]:checked+label{ box-shadow: 0 1px 3px 3px var(--color-focus); outline: var(--color-focus-border) solid 1px; background:none; }
.layerpopup--ondot .layerpopup__header{ display: block; text-align: center; }
.layerpopup--ondot .layerpopup__header .btn--close{ width:50px; height:3px; border-radius: 3px; background: #ddd; padding:0; margin:0 auto; }
.layerpopup--ondot .layerpopup__header h2{ font-size: 1.1em; color: #222; font-weight: bold; padding:.8em .2em .2em; }
.layerpopup--ondot .layerpopup__header p{ font-size: 1em; color: #888; padding: 0 .2em .2em; }

.layerpopup--ondot .layerpopup__footer{flex-direction: column; padding: 0.8em; align-items: center;}
.layerpopup--ondot .layerpopup__footer .btn--primary{ width:100%; border-radius: 8px; margin-bottom: .8em ; }
.layerpopup--ondot .layerpopup__footer .btn--cancel{ flex:0 0 auto; border-radius: 8px; width: auto; background: none; text-decoration: underline; color: #999999; }
/* Mobile & Tablet */
@media all and (min-width:480px) {
    .info{ padding: 4em 4em 0 1em; }
}

/* Tablet & Desktop */
@media all and (min-width:768px) {

    .footer__info{ flex:1; }
    .sc01__conts{ flex-direction: row; width:100%; max-width: 770px; margin: 0 auto; padding-bottom:0; }
    .sc01__conts >div.top--title{ flex: 1; max-width: 460px; padding: 0; margin-left: 1em; }
    .app-down{ padding:1.4em; }

    .info{ width: 100%; height: 100%; flex-direction: row; justify-content: space-between; align-items: revert; padding: 5em 3em 1em 1em; align-content: center; }
    .info__text{ padding:1em; flex: 1; align-self: flex-start; max-width: 480px; display: revert; }
    .info__text .title{ font-size: 3.2em; margin-right:0; }
    .info__text .subtitle{ font-size: 2.8em; padding-bottom: 0.6em;}
    .info__text .subtitle >span{ display:block; }
    .info__text .mo-text{ display:none; }
    .info__text .text{ display: block; }
    .info__text .text2{ display: block; padding-top: 2em; }
    .info__img{ padding:0 2em; height:80%; }
    .info__img .allphone{ height:100%; }
    .info--right .allphone{ margin-right:auto; margin-left:0; }
    .info--right .info__text{ order:2 }
    .info__img .phone{ width: 320px; height: 662px; }

    .footer__logo{ max-width: 340px; padding:.6em; }
    footer .btn__wrap{ margin-bottom: 3em; }
    footer .btn__wrap .btn{ padding: 0.8em 1.8em; margin-right:1em; }

    #fp-nav{ top:50%; }
    footer .cmt{ font-size: .85em; }
}

/* Desktop */
@media all and (min-width:1025px) {
    .info{ padding:5em 0 0 ; }
    .info__text{ padding-left:0; }
    .info__img{ padding-right:0; }
    .info__img .allphone{ max-width: 320px; }
    .info--right .info__text{ padding-left:2em; padding-right:0; }
    .info--right .info__img{ padding-right:2em; padding-left:0; }
    .login__wrap{ padding-top: 10em; }
}









