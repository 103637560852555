@charset "utf-8";
/* color 변수 */
:root{
    --color-default : #666;
    --color-black : #383943;

    --color-warning : #F44336;
    --color-guide : #82CA53;

    --input-border : 1px solid #aaa;
    --table-border : 1px solid #ddd;
}

body.scroll--no{ overflow: hidden !important; touch-action: none !important; }

/* text style */
.cmt{ font-size:.85em; color: var(--color-default); }
.cmt--warning{ color: var(--color-warning); }
.cmt--guide{ color:var(--color-guide); }

.text--line-1 { overflow: hidden; word-break: keep-all; word-wrap: break-word; white-space: nowrap; text-overflow: ellipsis; }
.text--line-2 { line-height: 1.3; overflow: hidden; word-break: keep-all; word-wrap: break-word; white-space: normal; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }

.required{ position: relative; padding-left: 5px ! important; }
.required::after{ content: ""; display: block; width: 5px; height: 5px; border-radius: 50%; background-color: var(--color-warning); position: absolute; top:0; left: 0; }

/* svg icon */
.svg-img{ width: 100%; height: 100%; transform: matrix(1, 0, 0, -1, 0, 0); }
.svg-img--default{fill:var(--color-default)}
.svg-img--black{fill:var(--color-black)}
.svg-img--warning{fill:var(--color-warning)}
.svg-img--guide{fill:var(--color-guide)}
.svg-img--primary{fill:var(--color-primary)}

/* a tag */
a:hover,
a:focus{
	box-shadow:none;
	outline: none;
	text-decoration: underline !important;
}

/* button reset */
.btn{ background: none; padding: 0.2em 0.6em; border: 1px solid #ddd; border-radius: 5px; font-size:1em; line-height:1; text-decoration: none; }
.btn:hover{ box-shadow: 0 1px 3px 3px var(--color-focus); outline: var(--color-focus-border) solid 1px; }

/* button style */
.btn--full{ width:100%; }
.btn--round{ border-radius: 80px; overflow: hidden; }
.btn--icon{ display: inline-flex; align-items: center; }
.btn__icon{ flex: 0 0 auto; width: .8em; }
.btn__text{ flex: 1; margin: 0 3px; word-break: break-all; word-wrap: break-word; }
.btn--text{ border-width: 0 0 1px 0; border-radius: 0; padding: 0 0 2px 0; background-color: transparent !important; }

.count{ display:flex; border: 1px solid #ddd; }
.count >span{ flex:1 1 60px; padding:.2em; font-size:.95em; border-right:1px solid #ddd; border-left:1px solid #ddd; background-color: #f9f9f9; text-align: center; }
.count .btn--icon{ flex:0 0 auto; border-radius: 0; border:0; padding:.2em .4em; z-index: 1; fill:#333;}

/* button color */
.btn--default
a.btn--default{ background-color: #F0F3F5; border-color: #B9C1CB; color: var(--color-black); fill: var(--color-black); }
.btn--primary,
a.btn--primary{ background-color: var(--color-primary); border-color: var(--color-primary); color: #FFFFFF; fill: #FFFFFF; }
.btn--secondary,
a.btn--secondary{ background-color: #EDF5FF; border-color: #C0CCDC; color: #445A7E; fill: #445A7E; }
.btn--info,
a.btn--info{ background-color: #FFFFFF; border-color: #B9C1CB; color: #848B97; fill: #848B97; }
.btn--cancel,
a.btn--cancel{ background-color: #e1e6ed; color:var(--color-default); fill:var(--color-default); }

.btn--text.btn--primary{ color:var(--color-primary); border-color: var(--color-primary);}

.btn--outline-default,
a.btn--outline-default{ background-color: #FFFFFF; border-color: #B9C1CB; color: var(--color-black); fill: var(--color-black); }
.btn--outline-primary,
a.btn--outline-primary{ background-color: #FFFFFF; border-color: var(--color-primary); color: var(--color-primary); fill: var(--color-primary); }
.btn--outline-secondary,
a.btn--outline-secondary{ background-color: #FFFFFF; border-color: #C0CCDC; color: #445A7E; fill: #445A7E; }
.btn--outline-info,
a.btn--outline-info{ background-color: #FFFFFF; border-color: #B9C1CB; color: #848B97; fill: #848B97; }
.btn--outline-guide,
a.btn--outline-guide{ background-color: #FFFFFF; border-color: var(--color-guide); color: var(--color-guide); fill: var(--color-guide); }
.btn--outline-warning,
a.btn--outline-warning{ background-color: #FFFFFF; border-color: var(--color-warning); color: var(--color-warning); fill: var(--color-warning); }

.btn--text:hover{ outline:none; box-shadow: none; border-bottom-width: 2px; }
.btn--default:hover,
.btn--outline-default:hover{ outline-color: var(--color-default); }
.btn--outline-guide:hover{ outline-color: var(--color-guide); }
.btn--outline-warning:hover{ outline-color: var(--color-warning); }
.btn--cancel:hover{ outline-color:#e1e6ed; }
/* button color E */

/* icon button */
.btn--close{ width: 30px; height: 100%; border: 0; padding: 0.4em; margin-left:5px; fill: #999; }
.btn--close:hover{ box-shadow: none; outline: none; fill: var(--color-primary);}

/* checkbox, radio reset */
.checkbox input[type="checkbox"],
.radio input[type="radio"]{
    clip: rect(0 0 0 0); width: 1px; height: 1px; border: 0; padding: 0; margin: -1px; overflow: hidden; position: absolute;
}
.checkbox >label,
.radio >label{
    display: inline-block;
}

/* checkbox, radio style */
/*.checkbox input[type="checkbox"]+label{ min-height: 1em; background: url(../icon/checkbox_nor.svg) no-repeat 0% 0.1em / 1em; padding-left: 1.2em; fonts-size: 1em; line-height: 1.2; vertical-align: middle; word-break: keep-all; word-wrap: break-word; cursor: pointer; }*/
/*.checkbox input[type="checkbox"]:checked+label{ background-image: url(../icon/checkbox_checked.svg); }            */
/*.radio input[type="radio"]+label{ min-height: 1em; background: url(../icon/radio_nor.svg) no-repeat 0% 0.1em / 1em; padding-left: 1.2em; fonts-size: 1em; line-height: 1.2; vertical-align: middle; word-break: keep-all; word-wrap: break-word; cursor: pointer; }*/
/*.radio input[type="radio"]:checked+label{ background-image: url(../icon/radio_checked.svg); }*/

.checkbox input[type="checkbox"]:focus+label,
.radio input[type="radio"]:focus+label,
.checkbox--btn input[type="checkbox"]:hover+label,
.radio--btn input[type="radio"]:hover+label{
    box-shadow: 0 1px 3px 3px var(--color-focus); outline: var(--color-primary) solid 1px;
}
.checkbox--btn input[type="checkbox"]+label,
.radio--btn input[type="radio"]+label{
    background: none; padding:.2em .8em; border-radius:5px; border:1px solid #bbb; color:#777;
}
.checkbox--btn input[type="checkbox"]:checked+label,
.radio--btn input[type="radio"]:checked+label{
    background: var(--color-primary); border-color: #445A7E; color: #FFFFFF;
}
/* checkbox, radio style E */

/* input reset */
.input{ background:#fff; padding:.4em; border: var(--input-border); border-radius:5px; font-size: 1em; line-height: 1.2; }

/* input style */
.input--line{ border-width: 0 0 1px 0; border-radius: 0; }
.input--icon{ display: flex; align-items: center; padding: 0.4em; }
.input__text{ border: 0; padding: 0; margin:0 3px; }
.input__icon{ width: 1em; fill: var(--color-default); }
.input--warning{ border-color: var(--color-warning); }

/* textarea */
.textarea{ width:100%; min-height:180px; padding:.4em; border: var(--input-border); border-radius:5px; font-size: 1em; line-height: 1.6; resize: vertical; }

/* select reset */
.select{ padding: 0 1.2em 0 0.4em; border: var(--input-border); border-radius: 5px; font-size: 1em; line-height: 1.2; }
.select--line{ border-width: 0 0 1px 0; border-radius: 0; }

/* box style */
.box--shadow{ box-shadow: 0 0 3px 1px rgb(0 0 0 / 20%); }
.box--border{ border:1px solid #dedede; }
.box--bg-white{ background-color: rgba(255,255,255,0.85); }
.box--bg-black{ background-color: rgba(0,0,0,0.4); }

/* states */
.h-readonly{ background: transparent !important; border-color: #ccc !important; color: #888 !important; }
.h-disabled{ background: var(--color-background) !important; border-color: #ccc !important; color: #666 !important; }
.h-disp--none{  display: none !important; }
.h-disp--block{ display: block !important; }
.h-disp--inline{ display: inline !important; }
.h-disp--inline-block{ display: inline-block !important; }
.h-disp--flex{ display: flex !important; }
.h-disp--inline-flex{ display: inline-flex !important; }

.hide{ border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }

/* Mobile & Tablet */
@media all and (min-width:480px) {

}

/* Tablet & Desktop */
@media all and (min-width:768px) {
    .cmt{ font-size: 14px;}
    .h-hide--tablet{ display:none !important; }
}


/* Desktop */
@media all and (min-width:1025px) {
    .h-hide--pc{ display:none !important; }
}