@keyframes slideDown {
    0%{
        opacity:0; transform:translateY(-150%);
    }
    20%{
        opacity:1; transform:translateY(0);
    }
    80%{
        opacity:1; transform:translateY(0);
    }
    100%{
        opacity:0; transform:translateY(-150%);
    }
}

@keyframes dialogSlide {
    0%{
        opacity:0; transform:translateY(-30%);
    }
    100%{
        opacity:1; transform:translateY(0);
    }
}

@keyframes slideUp {
    0%{
        opacity:0; transform:translateY(100%);
    }
    90%{
        opacity:.9; transform:translateY(1%);
    }
    100%{
        opacity:1; transform:translateY(0);
    }
}

@keyframes slideDown2 {
    0%{
        opacity:0; transform:translateY(-100%);
    }
    90%{
        opacity:.9; transform:translateY(-1%);
    }
    100%{
        opacity:1; transform:translateY(0);
    }
}

  @keyframes tt {
    0%{
        opacity:0; transform:translateY(20%);
    }
    33%{
        transform:translateY(-18%);
    }
    66%{
       transform:translateY(10%);
    }
    100%{
        opacity:1; transform:translateY(0%);
    }
  }

  @keyframes bounce {
	0% {
		opacity:0; transform: translateY(100%);
	}
	50%{
		transform: translateY(-8%);
	}
	65%{
		transform: translateY(4%);
	}
	80%{
		transform: translateY(-4%);
	}
	95%{
		transform: translateY(2%);
	}			
	100% {
		opacity:1; transform: translateY(0%);
	}	
}

@-webkit-keyframes bounce {
	0% {
		-webkit-transform: translateY(100%);
        opacity:0; 
	}
	50%{
		-webkit-transform: translateY(-8%);
	}
	65%{
		-webkit-transform: translateY(4%);
	}
	80%{
		-webkit-transform: translateY(-4%);
	}
	95%{
		-webkit-transform: translateY(2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
        opacity:1;
	}	
}


@keyframes opacity {
    0%{
        opacity:0; 
    }
    100%{
        opacity:1;
    }
}