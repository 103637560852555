
.charge-price {
  .price-info-top {
    margin: 1em 1em .2em 1em;
    padding-bottom: 15px;
    font-size: 17px;
    font-weight: 700;
    border-bottom: 1px solid #999999;
  }

  .price-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 1em 30px;
    border-bottom: 10px solid #f4f5f4;

    .flower-value {
      font-size: 17px;
    }

    .price-value {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .pay-buttons {
    display: flex;
    flex-direction: column;
    padding: 40px 1em;
    border-bottom: 10px solid #f4f5f4;

    .pay-button {
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 7px 0;
      background-color: white;
      border-radius: 20px;
      color: #373637;
      border: 1px solid #e6e5e5;

      &:active, &:focus {
        outline: none;
        border: 1px solid #86c057;

        span, ion-icon {
          color: #86c057;
        }
      }

      ion-icon {
        margin-right: 5px;
        font-size: 15px;
      }

      span {
        font-size: 15px;
      }
    }
  }

  .charge-cancel {
    display: flex;
    flex-direction: column;
    padding: 40px 1em;

    .cancel-button {
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 7px 0;
      border-radius: 20px;
      color: white;
      border: none;
      outline: none;
      background-color: #f13f54;

      ion-icon {
        margin-right: 5px;
        font-size: 16px;
      }

      span {
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
}

.charge-home-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.charge-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ion-icon {
    font-size: 50px;
    color: #88bf4d;
  }

  .result-title {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
    color: black;
  }

  .result-desc {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 600;
    color: black;
  }

  .flower-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    color: #ff5058;
    img {
      width: 25px;
      height: 25px;
    }

    .result-flower {
      font-size: 19px;
      font-weight: 600;
    }
  }

  .result-content {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 700;
    color: #ff5058;
  }

  button {
    margin-top: 40px;
    padding: 9px 30px;
    font-size: 13px;
    color: white;
    background-color: #88bf4d;
    border: none;
    border-radius: 5px;
  }
}

.charge-history {
  .history-title {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
  }
  .history-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px 10px;
    border-radius: 5px;
    border: 1px solid #d0d0d0;
    font-size: 13px;

    div:nth-child(1) {
      margin-right: 5px;
    }
    div:nth-child(2) {
      margin-right: 5px;
    }
    div:nth-child(3) {
      margin-right: 5px;
    }
    div:nth-child(4) {
      //flex: 1;
      text-align: center;
    }
    div:nth-child(5) {
    }
  }
}
.flower-history {
  max-height: 470px;
  overflow: auto;
  padding-right: 10px;
  .flower-history-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px 10px;
    border-radius: 5px;
    border: 1px solid #d0d0d0;
    font-size: 13px;

    div:nth-child(1) {
      margin-right: 25px;
    }

    div:nth-child(2) {
      flex: 1;
      margin-right: 5px;
    }

    div:nth-child(3) {
      flex: 1;
      margin-right: 5px;
    }

    div:nth-child(4) {
      flex: 2;
      text-align: center;
    }
  }
}

.customer-container {
  .customer-title {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 600;
  }
  .row-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
  }
  .row-item {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
