@charset "utf-8";
:root{
    --color-primary : #5275C6 ;
    --color-background : #EFF1F4;

    --color-status-cancel : #F36868;
    --color-status-receive : #868DBF;
    --color-status-confirm : #88BB6E;
    --color-status-wait : #FFC466;

    --color-focus : rgb(82 117 198 / 30%);
    --color-focus-border : #5275C6 ;
}

/* Mobile & Tablet */
@media all and (min-width:480px) {

}

/* Tablet & Desktop */
@media all and (min-width:768px) {

}


/* Desktop */
@media all and (min-width:1025px) {

}